footer {
  background-color: #180350;
  color: #efefef;
  padding: 5px;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  margin-top: 45px;
}

footer .MuiLink-root {
  color: #20a0a1 !important;
  text-decoration: none !important;
}

footer .MuiLink-root:active,
footer .MuiLink-root:focus,
footer .MuiLink-root:hover {
  text-decoration: underline !important;
}

@media only screen and (max-width: 499px) {
  #footer-madewith-link {
    display: block;
  }
}
