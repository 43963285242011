.lg-react-element > a {
  display: inline-block;
  padding: 0 5px 5px 5px;
  vertical-align: middle;
  position: relative;
  text-align: center;
}

.lg-react-element > a:active,
.lg-react-element > a:focus,
.lg-react-element > a:hover {
  top: 1px;
  right: -1px;
}
