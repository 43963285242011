#contactFormHoney {
  display: none;
  visibility: hidden;
}

#contact-card .MuiCardContent-root {
  background-color: #262837;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiInputBase-formControl {
  margin-bottom: 10px;
}

.MuiFormLabel-root {
  color: #018a5c !important;
}

.MuiSvgIcon-root {
  color: #efefef !important;
}

#contact-card .MuiInputBase-root .MuiSvgIcon-root {
  color: #000000 !important;
}

input:-internal-autofill-selected,
.MuiInputBase-root {
  background-color: #efefef !important;
  color: #000000 !important;
}

#resume-download-btn {
  float: right;
  background-color: #612597 !important;
}

@media only screen and (max-width: 520px) {
  #resume-download-btn {
    float: none;
    margin-top: 10px;
    display: flex;
    width: 235px;
  }
}
